import React from 'react'
import { Link } from 'gatsby'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <Link to={`/blog/${article.slug}`}>
      <div className={styles.preview__image}>
        <img
          className={styles.preview}
          alt=""
          src={article.heroImage.file.url}
        />
      </div>
      <div className={styles.preview__text}>
        <h3 className={styles.previewTitle}>{article.title}</h3>
        <small>{article.publishDate}</small>
        <div
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
        />
      </div>
    </Link>
  </div>
)
