import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';
import Contact from '../components/Contact';
import Helmet from 'react-helmet';

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulCaseStudies.edges');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <Layout location={this.props.location}>
        <Helmet title={`Blog | ${siteTitle}`} />
        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 blog">
                <h1>Case Studies</h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 section text-center">
              <h1 className="section--title">Recent posts</h1>
              <p className="section--copy">See what we've been up to</p>
            </div>
          </div>
          <div className="row">
            {posts.map(({ node }) => {
              return (
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={node.slug}>
                  <ArticlePreview article={node} />
                </div>
              );
            })}
          </div>
        </div>
        <Contact />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCaseStudies(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          # tags
          heroImage {
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
